import { differenceInYears, differenceInMonths, format } from 'date-fns';
import { startCase, omit } from 'lodash';

const AVATAR_COLOR_MAP = {
  'male:true': '#6CB2EB',
  'male:false': '#2563EB',
  'female:true': '#F69AB1',
  'female:false': '#EC4899',
};

export function formatName (nameObj, formatStr) {
  if (!nameObj || Object.keys(nameObj).length === 0) return '';

  const placeholders = {
    firstName: nameObj.firstName || '',
    lastName: nameObj.lastName || '',
    middleName: nameObj.middleName || '',
    middleInitial: nameObj.middleName ? nameObj.middleName.charAt(0).toUpperCase() + '.' : '',
  };

  return formatStr.split(',')
    .map(s => s.trim())
    .map(key => placeholders[key] || '')
    .join(', ')
    .trim();
}

export function mapPersonalDetails (personalDetails = {}) {
  const isMinor = personalDetails?.dateOfBirth && differenceInYears(new Date(), new Date(personalDetails.dateOfBirth)) < 18;
  const sex = personalDetails?.sex;
  const data = {
    ...personalDetails,
    formattedName: formatName(personalDetails?.name || {}, 'lastName, firstName'),
    formattedLastName: startCase(personalDetails?.name?.lastName),
    formattedMiddleName: startCase(personalDetails?.name?.middleName),
    formattedFirstName: startCase(personalDetails?.name?.firstName),
    formattedAddress: formatAddress(personalDetails?.address, 'street1, street2, city, municipality, province, state, country, zipCode'),
    formattedSex: startCase(personalDetails?.sex),
    formattedSexInitial: personalDetails?.sex?.charAt(0).toUpperCase(),
    formattedPhoneNumbers: formatPhoneNumbers(personalDetails),
    formattedDateOfBirth: personalDetails?.dateOfBirth && format(personalDetails.dateOfBirth, 'MMM dd, yyyy'),
    formattedAge: personalDetails?.dateOfBirth && differenceInYears(new Date(), new Date(personalDetails?.dateOfBirth)),
    formattedAgeInMonths: personalDetails?.dateOfBirth && differenceInMonths(new Date(), new Date(personalDetails?.dateOfBirth)),
    isMinor,
    sexAgeColor: AVATAR_COLOR_MAP[`${sex}:${isMinor}`],
  };

  if (personalDetails.isDoctor) {
    data.formattedSpecialization = personalDetails?.doc_specialties?.length ? startCase(personalDetails.doc_specialties?.[0]) : '';
    data.formattedSpecializations = personalDetails?.doc_specialties?.length ? personalDetails.doc_specialties?.map(s => startCase(s)).join(', ') : '';
  }

  return data;
}

export function formatAddress (addressObj, formatStr) {
  if (!addressObj || Object.keys(addressObj).length === 0) return '';

  if (addressObj.formattedAddress) return addressObj.formattedAddress;

  const placeholders = {
    street1: addressObj.street1 || '',
    street2: addressObj.street2 || '',
    village: addressObj.village || '',
    city: addressObj.city || '',
    municipality: addressObj.municipality || '',
    province: addressObj.province || '',
    state: addressObj.state || '',
    region: addressObj.region || '',
    country: addressObj.country || '',
    zipCode: addressObj.zipCode || '',
  };

  return formatStr.split(',')
    .map(s => s.trim())
    .map(key => placeholders[key] || '')
    .filter(Boolean)
    .join(', ')
    .trim();
}

export function formatPhoneNumbers (personalDetails) {
  const phoneTypes = [
    {
      field: 'mobileNo',
      type: '<i class="las la-mobile text-lg font-medium"></i>',
    },
    {
      field: 'homeNo',
      type: '<i class="las la-home text-lg font-medium"></i>',
    },
    {
      field: 'workNo',
      type: '<i class="las la-briefcase text-lg font-medium"></i>',
    },
  ];

  const phoneNoArray = phoneTypes.reduce((acc, type) => {
    const value = personalDetails?.[type.field];
    if (value) {
      acc.push({
        type: type.type,
        value,
      });
    }
    return acc;
  }, []);

  if (!phoneNoArray.length) return '';
  return phoneNoArray.map(phoneNo => `${phoneNo.type} ${phoneNo.value}`).join('<br>');
}

export function formatRecord (record) {
  if (!record) return null;
  const relative = record.$populated?.relative;
  const service = record.$populated?.service;
  const encounter = record.$populated?.encounter;
  return {
    ...omit(record, ['$populated']),
    relativeData: relative,
    encounterData: encounter,
    formattedEncounterCreatedAt: encounter?.createdAt && format(encounter.createdAt, 'MMM dd, yyyy'),
    formattedCreatedAt: record.createdAt && format(record.createdAt, 'MMM dd, yyyy'),
    formattedCreatedAtFull: record.createdAt && format(record.createdAt, 'MMM dd, yyyy hh:mm a'),
    formattedFinalizedAt: record.finalizedAt && format(record.finalizedAt, 'MMM dd, yyyy'),
    formattedFinalizedAtFull: record.finalizedAt && format(record.finalizedAt, 'MMM dd, yyyy hh:mm a'),
    formattedType: record.type && startCase(record.type),
    formattedCreatedByPicURL: record.createdByDetails?.picURL,
    formattedCreatedByFullName: record.createdByDetails?.name && formatName(record.createdByDetails?.name, 'lastName, firstName'),
    formattedRelativeDateOfBirth: relative?.dateOfBirth && format(relative.dateOfBirth, 'MMM dd, yyyy'),
    formattedRelativeDeceasedAt: relative?.deceasedAt && format(relative.deceasedAt, 'MMM dd, yyyy'),
    formattedService: service,
  };
}
